import React, { FC, SVGAttributes } from 'react'

const RightArrowIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 16 15" {...props}>
    <path
      fill="current"
      stroke="current"
      strokeWidth="0.5"
      d="M12.138 6.625H1.75a.875.875 0 100 1.75h10.388L8.01 12.504a.875.875 0 101.237 1.237l5.622-5.623h0a.875.875 0 000-1.236h0L9.247 1.259A.875.875 0 108.01 2.496l4.128 4.129z"
    />
  </svg>
)

export default RightArrowIcon

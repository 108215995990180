'use client'
import React, { type FC, useEffect, useState } from 'react'
import Card from '../Atoms/Card'
import InputField from 'ui/components/FormFields/InputField'
import Button from 'ui/components/Button'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import messages from 'ui/i18n/messages/nl'
import RightArrowIcon from '../../icons/rightArrowIcon'
import Link from 'next/link'
import { medusaClient } from 'web/src/lib/config'
import { useAccount } from 'web/src/lib/context/account-context'
import { useRouter } from 'next/navigation'
import Error from 'next/error'
import routes from 'web/src/lib/routes'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStore } from 'web/src/lib/context/store-context'

const schema = Yup.object({
    email: Yup.string().required(messages.fieldRequired).email(messages.emailAddressNotValid),
    password: Yup.string().required(messages.fieldRequired).min(8, messages.passwordTooShort),
})

type FormSchema = Yup.InferType<typeof schema>

const Login: FC = () => {
    const [authError, setAuthError] = useState<string | undefined>(undefined)
    const { refetchCustomer } = useAccount()
    const router = useRouter()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<FormSchema>({
        resolver: yupResolver(schema),
        defaultValues: { email: '', password: '' },
    })

    const handleError = (_e: Error) => {
        setAuthError(messages.unknowUser)
    }

    const onSubmit = async (values: FormSchema) => {
        medusaClient.auth
            .authenticate(values)
            .then(async () => {
                await refetchCustomer()
                router.push(routes.account)
            })
            .catch(handleError)
    }

    return (
        <>
            <h2 className="mb-7">Hé, leuk je weer te zien!</h2>
            <Card mediumPadding>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className={'font-bold text-xl mb-4'}>Inloggen</p>
                    <div className={'gap-2.5 flex flex-col'}>
                        <InputField
                            {...register('email')}
                            id="email"
                            type="email"
                            label="E-mailadres"
                            required
                            error={errors?.email?.message}
                            placeholder="example@example.com"
                        />
                        <InputField
                            {...register('password')}
                            id="2"
                            type="password"
                            label="Wachtwoord"
                            required
                            placeholder="******"
                            error={errors?.password?.message}
                        />
                    </div>
                    <div className={'flex items-center justify-center flex-col'}>
                        <Button
                            aria-label={'Inloggen'}
                            loading={isSubmitting}
                            type="submit"
                            className="w-full btn-primary mt-5"
                            rightIcon={<RightArrowIcon stroke={'#fff'} fill={'#fff'} />}
                        >
                            Inloggen
                        </Button>
                        {authError && <span className="error mt-2">{authError}</span>}
                        <Link
                            aria-label="Wachtwoord vergeten?"
                            href={routes.forgotPassword}
                            className={'mt-3 underline text-sm'}
                        >
                            Wachtwoord vergeten?
                        </Link>
                    </div>
                </form>
            </Card>
            <div className="mt-20 text-center">
                <p className="font-bold text-xl mb-5">Word jij ook een Good Chef?</p>
                <p className="max-w-md mx-auto">
                    Wijzig, pauzeer of stop je abonnement, wanneer je wilt. Plantaardig lekker eten bij je thuisbezorgd.
                </p>
                <Button
                    aria-label={'Kies mijn maaltijdbox'}
                    // @ts-ignore
                    as={Link}
                    rightIcon={<RightArrowIcon stroke={'#000'} fill={'#000'} />}
                    className="mt-5 px-11 btn-danger lg:hover:text-white text-dark bg-transparent border-dark lg:hover:!bg-dark lg:[&>svg]:hover:fill-white lg:[&>svg]:hover:stroke-white w-auto"
                    href={routes.products}
                >
                    Kies mijn maaltijdbox
                </Button>
            </div>
        </>
    )
}

export default Login

const messages = {
    fieldRequired: 'Dit veld is verplicht.',
    postcodeNotValid: 'Vul een postcode in met het formaat 1234AB.',
    phoneNotValid: 'Vul een telefoonnummer in van 10 cijfers.',
    emailAddressNotValid: 'Vul een geldig e-mailadres in.',
    passwordTooShort: 'Een wachtwoord moet minimaal 8 tekens bevatten.',
    passwordDoNotMatch: 'De wachtwoorden komen niet overeen.',
    unknownError: 'Sorry, er is iets misgegaan aan onze kant. Probeer het opnieuw of neem contact met ons op.',
    emailNotExists: 'Er is geen account bekend voor het opgegeven e-mailadres.',
    emailExists: 'Er bestaat al een account met dit e-mailadres.',
    passwordResetSend: 'Er is een e-mail verstuurd voor het instellen van uw wachtwoord.',
    passwordResetSuccessful: 'Het wachtwoord is opnieuw ingestgeld.',
    expiredToken: 'Het wachtwoord reset token is verlopen.',
    AccountCreationFailed:
        'Er ging wat mis met het aanmaken van je account. Probeer het opnieuw of neem contact met ons op.',
    passwordNotValid: 'Het wachtwoord moet bestaan uit minimaal 8 karakters, 1 hoofdletter en 1 cijfer.',
    unknowUser: 'Sorry, we herkennen uw account niet. Controleer uw login gegevens en probeer het opnieuw.',

    // statuscode
    '401': 'Sorry, we herkennen uw account niet. Controleer uw gegevens en probeer het opnieuw, of neem contact met ons op.', // unknowUser
    '422': 'Er bestaat al een account met dit e-mailadres. Probeer een ander e-mailadres of log in op uw account.', //emailExists
    '4xx': 'Sorry, er is iets misgegaan aan onze kant. Probeer het later opnieuw of neem contact met ons op.',
}

export default messages
